import {mapGetters, mapActions} from 'vuex';

import DataService from '@/services/data.service';
const service = new DataService();

export default {
  name: 'index',

  data() {
    return {
      step: 1,
    }
  },

  methods: {
    ...mapGetters([
      'getUploadImportFileStep',
      'getUploadImportFileData'
    ]),

    ...mapActions([
      'setUploadImportFileStep',
    ]),

    async next() {
      switch (this.step) {
        case 1:
          this.setUploadImportFileStep('Match');
          await this.$router.push({name: 'ImportLegalDocumentMatch'});
          this.step = 2;
          break;
        case 2:
          this.setUploadImportFileStep('Review');
          await this.$router.push({name: 'ImportLegalDocumentReview'});
          this.step = 3;
          break;
      }
    },

    async back() {
      switch (this.step) {
        case 2:
          this.setUploadImportFileStep('Upload');
          await this.$router.push({name: 'ImportLegalDocumentUpload'});
          this.step = 1;
          break;
        case 3:
          this.setUploadImportFileStep('Match');
          await this.$router.push({name: 'ImportLegalDocumentMatch'});
          this.step = 2;
          break;
      }
    },

    async importData() {
      const legalDocument = this.getUploadImportFileData()?.legalDocument;

      const res = await service.mapLegalDocument(legalDocument);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('process_import_success')});
        this.status = 'completed';
        await this.$router.push({name: 'LegalDocumentsList'});
      } else {
        this.status = 'error';
      }
    },

    validateBeforeNext() {
      return !this.getUploadImportFileData();
    }
  }
}