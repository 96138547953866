import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].data_server;

class DataService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async ImportGeojsonZipCodes(data) {
    try {
      const response = await this.http.post(`${server_base}/maps/import`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async importCarSales(file) {
    try {
      const response = await this.http.post(`${server_base}/sales/import`, file);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async importSalesLocations() {
    try {
      const response = await this.http.post(`${server_base}/sales/import-locations`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listImportedCarSalesStats(limit, skip) {
    try {
      this.addLoading('list');
      const response = await this.http.get(`${server_base}/sales/import`, {limit, skip});
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listImportedLocations(limit, skip, {status}) {
    try {
      const response = await this.http.get(`${server_base}/sales/import-locations`, {limit, skip, status});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async createLegalDocument(data) {
    try {
      const response = await this.http.post(`${server_base}/legal-doc/create`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async mapLegalDocument(data) {
    try {
      const response = await this.http.put(`${server_base}/legal-doc/map`, data);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async getFieldsToMap() {
    try {
      const response = await this.http.get(`${server_base}/legal-doc/fields-to-map`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
}

export default DataService;