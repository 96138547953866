import { render, staticRenderFns } from "./Index.html?vue&type=template&id=42054ebe&scoped=true&external"
import script from "./Index.js?vue&type=script&lang=js&external"
export * from "./Index.js?vue&type=script&lang=js&external"
import style0 from "./Index.scss?vue&type=style&index=0&id=42054ebe&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42054ebe",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FDataImport%2FLegalDocuments%2FIndex%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports